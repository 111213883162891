<template>
  <div class="UserDomains">
    <md-card-header class="card-header-container">
      <h2 class="o-default-h2">{{ $t('userDomains.title') }}</h2>
      <span class="o-default-body">{{ $t('userDomains.description') }}</span>
    </md-card-header>

    <div v-if="userDomainsError">
      <ErrorCard :mainMessage="messages.error.main" :optionalMessage="messages.error.email" />
    </div>
    <div v-else>
      <div v-if="isUserDomainsLoading" class="user-domains-preview">
        <h3 class="o-default-h3 u-text-center">{{ $t('userDomains.domainList.loading') }}</h3>
        <Loader />
      </div> <!-- /.user-domains-preview -->

      <div v-else>
        <div v-if="searchedReservedDomains.length === 0 && searchedRegisteredDomains.length === 0"
          class="user-domains-preview o-default-body">
          {{ $t('userDomains.domainList.noUserDomains') }}
        </div> <!-- /.user-domains-preview -->
        <md-table class="reserved-domains-table" md-card v-model="searchedReservedDomains"
          @md-selected="onSelectReservedDomains">
          <md-table-toolbar class="count-toolbar" slot="md-table-alternate-header" slot-scope="{ count }">
            <md-button class="md-dense md-raised md-primary" v-if="reservedDomains.length > 0"
              @click="addToCartReservedDomains(reservedDomains)">
              {{ $t('searchedDomains.addBtn') }}
            </md-button>
            <div>{{ $tc('common.domainSearch.searchedDomainsPlaceholder', count) }}</div>
            <md-button @click="deleteSelectedReservedEntries" class="md-icon-button">
              <md-icon>close</md-icon>
              <md-tooltip md-direction="bottom">{{ $t('common.actions.close') }}</md-tooltip>
            </md-button>
          </md-table-toolbar>
          <md-table-toolbar class="header-toolbar">
            <div class="table-header md-title u-font-body-regular">
              <div @click="showDomainInfoDialog = true" class="table-tooltip-help">
                <i class="material-icons md-24">help_outline</i>
                <h2 class="o-default-h2">{{ $t('domainNamesView.reserveTitle') }}</h2>
              </div>
              <md-field md-clearable class="md-toolbar-section-center">
                <md-input :placeholder="$t('common.domainSearch.searchPlaceholder')" v-model="searchReservedDomains"
                  @input="searchOnReservedTable" />
              </md-field>
            </div>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }"
            :md-disabled="item.addedToCart || isReservedItemInCart(item)" md-selectable="multiple" md-auto-select>
            <md-table-cell class="domain-name" :md-label="labels.name" md-sort-by="name">
              {{ item.domainName }}
            </md-table-cell>
            <md-table-cell :md-label="labels.domainStatus" md-sort-by="domainStatus" class="user-domain-status u-strong"
              v-bind:class="getDomainStatusLabelClass(item.domainStatus)">
              {{ $t('userDomains.domainStatus.reserved') }}
            </md-table-cell>
            <md-table-cell :md-label="labels.domainPrice" md-sort-by="domainPrice">
              <span class="u-strong">{{ $t('cartItems.euroSign') }}{{ item.priceEuro.toFixed(2) }}</span>
            </md-table-cell>
            <md-table-cell :md-label="labels.domainReservationExpireDate">
              <p v-if="item.reservationExpireDate" class="o-default-body-small">{{ item.reservationExpireDate |
                moment("DD.MM.YYYY") }} {{ $t('auctionView.atHour') }} {{ item.reservationExpireDate | moment("HH:mm")
                }}</p>
            </md-table-cell>
            <md-table-cell>
              <md-button v-if="!isReservedItemInCart(item)"
                @click="addToCartSelectedReservedDomain(item.id, item.domainStatus)" class="md-icon-button">
                <md-icon>add_shopping_cart</md-icon>
                <md-tooltip md-direction="right">{{ $t('common.actions.addToCart') }}</md-tooltip>
              </md-button>
              <md-button v-if="isReservedItemInCart(item)" @click="deleteDomainCart(item.id)" class="md-icon-button">
                <md-icon>remove_shopping_cart</md-icon>
                <md-tooltip md-direction="right">{{ $t('common.actions.deleteDomainFromCart') }}</md-tooltip>
              </md-button>
            </md-table-cell>
          </md-table-row>
          <md-table-empty-state v-if="searchedReservedDomains.length === 0"
            :md-label="$t('common.error.noDomainsFound')"
            :md-description="$t('common.error.noReservedDomainsMatchingContent', { searchReservedDomains: this.searchReservedDomains })">
          </md-table-empty-state>
        </md-table>
        <md-table class="registered-domains-table" md-card v-model="searchedRegisteredDomains"
          @md-selected="onSelectRegisteredDomains">
          <md-table-toolbar class="count-toolbar" slot="md-table-alternate-header" slot-scope="{ count }">
            <md-button v-if="registeredDomains.length > 0" class="md-dense md-raised md-primary"
              @click="addToCartRegisteredDomains(registeredDomains);">
              {{ $t('searchedDomains.addBtn') }}
            </md-button>
            <div>{{ $tc('common.domainSearch.searchedDomainsPlaceholder', count) }}</div>
            <md-button @click="deleteSelectedRegisteredEntries" class="md-icon-button">
              <md-icon>close</md-icon>
              <md-tooltip md-direction="bottom">{{ $t('common.actions.close') }}</md-tooltip>
            </md-button>
          </md-table-toolbar>
          <md-table-toolbar class="header-toolbar">
            <div class="table-header md-title u-font-body-regular">
              <div @click="showDomainInfoDialog = true" class="table-tooltip-help">
                <i class="material-icons md-24">help_outline</i>
                <h2 class="o-default-h2">{{ $t('domainNamesView.renewTitle') }}</h2>
              </div>
              <md-field md-clearable class="md-toolbar-section-center">
                <md-input :placeholder="$t('common.domainSearch.searchPlaceholder')" v-model="searchRegisteredDomains"
                  @input="searchOnRegisteredTable" />
              </md-field>
            </div>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }"
            :md-disabled="item.addedToCart || isRegisteredItemInCart(item)" md-selectable="multiple" md-auto-select>
            <md-table-cell class="domain-name" :md-label="labels.name" md-sort-by="name">
              <div @click.exact="goToUserDomainView(item.tld, item.domain)"
                @click.ctrl.exact="goToUserDomainViewNewTab($event, { tld: item.tld, domain: item.domain })"
                @contextmenu.prevent="$refs.ctx.open($event, { tld: item.tld, domain: item.domain })">
                {{ item.domain }}.{{ item.tld }}
              </div>
              <md-tooltip v-if="tooltipEnabled" md-direction="bottom">
                {{ $t('userDomains.clickToNavigateToUserDomain') }}
              </md-tooltip>
            </md-table-cell>
            <md-table-cell :md-label="labels.domainStatus" md-sort-by="domainStatus" class="user-domain-status u-strong"
              v-bind:class="getDomainStatusLabelClass(item.domainStatus)">
              {{ displayUserDomainStatus(item.domainStatus) }}
            </md-table-cell>
            <md-table-cell :md-label="labels.domainPrice" md-sort-by="domainPrice">
              <span class="u-strong">{{ renewDomainsPrice }}</span>
            </md-table-cell>
            <md-table-cell :md-label="labels.expireDate" md-sort-by="expireDate">
              {{ item.expireDate }}
            </md-table-cell>
            <md-table-cell :md-label="labels.nameservers" md-sort-by="nameservers">
              <div v-for="(nameserver, key, index) of item.nameservers" :key="key">
                <span v-if="nameserver">
                  {{ index + 1 }}: <span class="u-strong">{{ nameserver }}</span>
                </span>
              </div>
            </md-table-cell>
            <md-table-cell v-if="temporary.totalDomainsWithPrivateNameservers !== 0"
              :md-label="labels.privateNameservers" md-sort-by="privateNameservers">
              <div v-for="(privateNameserver) of item.privateNameservers" :key="privateNameserver.name">
                <span v-if="privateNameserver">
                  <span class="u-strong">{{ privateNameserver.name }}</span> →
                  <span v-if="privateNameserver.ip1">{{ privateNameserver.ip1 }}</span>
                  <span v-if="privateNameserver.ip2"> | {{ privateNameserver.ip2 }}</span>
                </span>
              </div>
            </md-table-cell>
            <md-table-cell>
              <md-button v-if="!isRegisteredItemInCart(item)"
                @click="addToCartSelectedRegisteredDomain(item.id, item.domainStatus)" class="md-icon-button">
                <md-icon>add_shopping_cart</md-icon>
                <md-tooltip md-direction="right">{{ $t('common.actions.addToCart') }}</md-tooltip>
              </md-button>
              <md-button v-if="isRegisteredItemInCart(item)" @click="deleteDomainCart(item.id)" class="md-icon-button">
                <md-icon>remove_shopping_cart</md-icon>
                <md-tooltip md-direction="right">{{ $t('common.actions.deleteDomainFromCart') }}</md-tooltip>
              </md-button>
            </md-table-cell>
            <context-menu ref="ctx" @ctx-open="onCtxOpen" @ctx-close="onCtxClose" @ctx-cancel="resetCtxLocals">
              <li class="o-context-menu-li" @click="goToUserDomainViewNewTab($event, contextMenuData)">
                {{ $t('userDomains.openUserDomainInNewTab') }}
              </li>
            </context-menu>
          </md-table-row>
          <md-table-empty-state v-if="searchedRegisteredDomains.length === 0"
            :md-label="$t('common.error.noDomainsFound')"
            :md-description="$t('common.error.noRegisteredDomainsMatchingContent', { searchRegisteredDomains: this.searchRegisteredDomains })">
          </md-table-empty-state>
        </md-table>
        <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration"
          :md-active.sync="snackbar.showSnackbar" md-persistent>
          <span>{{ this.messages.addedToCart }}</span>
          <md-button class="md-primary" @click="snackbar.showSnackbar = false">{{ $t('common.actions.close')
            }}</md-button>
        </md-snackbar>
        <md-snackbar :md-position="snackbarDeletedFromCart.position" :md-duration="snackbarDeletedFromCart.duration"
          :md-active.sync="snackbarDeletedFromCart.showSnackbarDeletedFromCart" md-persistent>
          <span>{{ $t('minicart.delete') }}</span>
          <md-button class="md-primary" @click="snackbarDeletedFromCart.showSnackbarDeletedFromCart = false">{{
            $t('common.actions.close') }}</md-button>
        </md-snackbar>
        <div class="o-container">
          <PaginationBlock :pages="pages" :currentPage.sync="currentPage" />
        </div>
      </div>
      <md-dialog :md-active.sync="showDomainInfoDialog">
        <md-dialog-title>{{ $t('common.userDomains.title') }}</md-dialog-title>
        <md-dialog-content>
          <p class="u-font-body-regular o-dialog-content">{{ $t('common.userDomains.domainsSelection1') }}</p>
          <p class="u-font-body-regular o-dialog-content">{{ $t('common.userDomains.domainsSelection2') }}</p>
          <p class="u-font-body-regular o-dialog-content">{{ $t('common.userDomains.domainsSelection3') }}</p>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="this.hideDomainSelectionDialog">{{ $t('common.actions.close')
            }}</md-button>
        </md-dialog-actions>
      </md-dialog>
      <md-dialog :md-active.sync="showAddToCartErrorDialog">
        <md-dialog-title>{{ $t('common.userDomains.title') }}</md-dialog-title>
        <md-dialog-content>
          <p class="u-font-body-regular o-dialog-content">{{ $t('userDomainsStatus.alreadyAdded') }}</p>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="this.hideDomainAlreadyAddedDialog">{{ $t('common.actions.close')
            }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div> <!-- /.UserDomains -->
</template>

<script>
import { mapGetters } from 'vuex';
import ContextMenu from 'vue-context-menu';
import {
  ADD_TO_CART,
  DELETE_ENTRY,
  FETCH_CART,
  FETCH_CAPTURED_DOMAINS,
  FETCH_USER_DOMAINS,
} from '@/store/actions.type';
import { SET_ADD_ERROR } from '@/store/mutations.type';
import PaginationBlock from '@/components/util/PaginationBlock';
import ErrorCard from '@/components/util/ErrorCard';
import Loader from '@/components/util/Loader';
import Helpers from '@/common/helpers.js';

export default {
  name: 'UserDomains',
  components: {
    ContextMenu,
    ErrorCard,
    Loader,
    PaginationBlock
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 1000
    },
    cardTitle: String
  },
  data() {
    return {
      snackbar: {
        showSnackbar: false,
        duration: 2000,
        position: 'center',
      },
      snackbarDeletedFromCart: {
        showSnackbarDeletedFromCart: false,
        duration: 2000,
        position: 'center',
      },
      capturedDomains: [],
      contextMenuData: {},
      currentPage: 1,
      domainIdInCart: null,
      domainNames: null,
      registeredDomains: [],
      renewDomainsPrice: '€7.00',
      reservedDomains: [],
      searchedRegisteredDomains: [],
      searchedReservedDomains: [],
      searchRegisteredDomains: null,
      searchReservedDomains: null,
      showAddToCartErrorDialog: false,
      showDomainInfoDialog: false,
      tooltipEnabled: true,
      labels: {
        domainPrice: this.$t('common.price'),
        domainReservationExpireDate: this.$t('userDomains.labels.expireDateReservedDomains'),
        domainStatus: this.$t('userDomains.labels.domainStatus'),
        expireDate: this.$t('userDomains.labels.expireDate'),
        name: this.$t('userDomains.labels.name'),
        nameservers: this.$t('userDomains.labels.nameservers'),
        paid: this.$t('userDomains.labels.paid'),
        priceEuro: this.$t('userDomains.labels.priceEuro'),
        priceRon: this.$t('userDomains.labels.priceRon'),
        privateNameservers: this.$t('userDomains.labels.privateNameservers'),
      },
      domainStatusMap: {
        reserved: 'reserved',
        registered: 'registered',
        pendingDelete: 'pendingDelete'
      },
      temporary: {
        totalDomainsWithPrivateNameservers: 0
      },
      messages: {
        addedToCart: this.$t('userDomainsStatus.addedToCart'),
        error: {
          main: this.$t('userDomains.error.connectionError'),
          email: this.$t('contactEmailInreg'),
          addCart: '',
          alreadyAdded: this.$t('userDomainsStatus.alreadyAdded'),
        }
      },
    };
  },
  computed: {
    ...mapGetters([
      'addToCartError',
      'entries',
      'isAuthenticated',
      'isUserDomainsLoading',
      'userDomains',
      'userDomainsCount',
      'userDomainsError',
      'userCapturedDomains'
    ]),
    listConfig() {
      const filters = {
        domainStatus: 'PendingDelete,OK',
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage
      };
      return {
        filters
      };
    },
    pages() {
      if (this.isUserDomainsLoading || this.userDomainsCount <= this.itemsPerPage) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.userDomainsCount / this.itemsPerPage)).keys()
      ].map(e => e + 1);
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.filters.offset = (newValue - 1) * this.itemsPerPage;
    },
  },
  created() {
    this.fetchCart()
    this.updateTableWithCartItems();
  },
  mounted() {
    this.fetchUserDomains();
    this.fetchUserDomainsCaptured();
    this.domainNames = this.setDomainNamesInCart();
    this.domainIdInCart = this.setDomainIdInCart();
  },
  methods: {
    isReservedItemInCart(item) {
      if (this.entries) {
        return this.entries.some(cartItem => cartItem.id === item.id)
      }
    },
    isRegisteredItemInCart(item) {
      if (this.entries) {
        return this.entries.some(cartItem => cartItem.id === item.id)
      }
    },
    deleteDomainCart(item) {
      let slug = "?entryId=" + item;
      this.$store.dispatch(DELETE_ENTRY, slug).then(() => {
        this.showCopyConfirmationDeletedFromCart();
        this.updateTableWithCartItems();
        this.updateTableAfterDelete(item);
      });
    },
    hideDomainSelectionDialog() {
      this.showDomainInfoDialog = false;
    },
    hideDomainAlreadyAddedDialog() {
      this.showAddToCartErrorDialog = false;
    },
    setDomainNamesInCart() {
      if (this.entries) {
        let domainNamesInCart = this.entries.map((e) => e.productName);
        return domainNamesInCart.toString();
      }
    },
    setDomainIdInCart() {
      if (this.entries) {
        let domainIdInCart = this.entries.map((e) => e.id);
        return domainIdInCart.toString();
      }
    },
    addToCartRegisteredDomains(params) {

      const domainStatusToDomainType = new Map([
        ['registered', 'RENEW'],
        ['reserved', 'REGISTER'],
        ['pendingDelete', 'RENEW']
      ]);

      params.forEach(domain => {
        if (domain.domainStatus && domainStatusToDomainType.has(domain.domainStatus)) {
          domain.domainType = domainStatusToDomainType.get(domain.domainStatus);
        }
        domain.period = 1;
      });

      this.$store.dispatch(ADD_TO_CART, params).then(() => {
        if (this.addToCartError === false) {
          this.showCopyConfirmation();
          this.updateTableAfterAdd(params);
          this.fetchUserDomains();
        } else {
          this.messages.error.addCart = this.messages.error.alreadyAdded;
          this.showAddToCartErrorDialog = true;
          this.$store.commit(SET_ADD_ERROR, false);
        }
      });
    },
    addToCartReservedDomains(params) {
      params.forEach(domain => domain.domainType = 'RESERVE');

      this.$store.dispatch(ADD_TO_CART, params).then(() => {
        if (this.addToCartError === false) {
          this.showCopyConfirmation();
          this.updateTableAfterAdd(params);
          this.fetchUserDomainsCaptured();
        } else {
          this.messages.error.addCart = this.messages.error.alreadyAdded;
          this.showAddToCartErrorDialog = true;
          this.$store.commit(SET_ADD_ERROR, false);
        }
      });
    },
    addToCartSelectedReservedDomain(id, domainStatus, params) {
      domainStatus = 'RESERVE';
      params = [{
        id: id,
        domainType: domainStatus,
        period: 1,
      }];
      this.$store.dispatch(ADD_TO_CART, params).then(() => {
        if (this.addToCartError === false) {
          this.showCopyConfirmation();
          this.updateTableWithCartItems();
          this.updateTableAfterAdd(params);
          this.fetchUserDomainsCaptured();
        } else {
          this.messages.error.addCart = this.messages.error.alreadyAdded;
          this.showAddToCartErrorDialog = true;
          this.$store.commit(SET_ADD_ERROR, false);
        }
      });
    },
    addToCartSelectedRegisteredDomain(id, domainStatus, params) {
      domainStatus = 'RENEW';
      params = [{
        id: id,
        domainType: domainStatus,
        period: 1,
      }];
      this.$store.dispatch(ADD_TO_CART, params).then(() => {
        if (this.addToCartError === false) {
          this.showCopyConfirmation();
          this.updateTableWithCartItems();
          this.updateTableAfterAdd(params);
          this.fetchUserDomains();
        } else {
          this.messages.error.addCart = this.messages.error.alreadyAdded
          this.showAddToCartErrorDialog = true;
          this.$store.commit(SET_ADD_ERROR, false);
        }
      });
    },
    fetchCart() {
      this.$store.dispatch(FETCH_CART);
      this.totalPrice = this.totalAmountEuro;
    },
    fetchUserDomains() {
      this.$store.dispatch(FETCH_USER_DOMAINS, this.listConfig).then(() => {
        const filteredUserDomains = this.userDomains.filter(domain => domain.domainStatus === 'registered' || domain.domainStatus === 'pendingDelete');

        const registeredEntryMap = new Map();
        if (this.entries && this.entries.length > 0) {
          this.entries.forEach(entry => {
            registeredEntryMap.set(entry.productName, entry.id);
          });
        }

        filteredUserDomains.forEach(domain => {
          if (domain.name) {
            if (registeredEntryMap.has(domain.name)) {
              domain.id = registeredEntryMap.get(domain.name);
            }
          }
        });

        this.searchedRegisteredDomains = filteredUserDomains;

        for (const key in this.userDomains) {
          if (Object.prototype.hasOwnProperty.call(this.userDomains, key)) {
            const userDomainPrivateNameserver = this.userDomains[key].privateNameservers;
            if (userDomainPrivateNameserver.length !== 0) {
              this.temporary.totalDomainsWithPrivateNameservers++;
            }
          }
        }
      });
    },
    fetchUserDomainsCaptured() {
      this.$store.dispatch(FETCH_CAPTURED_DOMAINS).then(() => {
        if (this.userCapturedDomains && typeof this.userCapturedDomains === 'object') {
          this.searchedReservedDomains = Object.values(this.userCapturedDomains);

          const reservedEntryMap = new Map();
          if (this.entries && this.entries.length > 0) {
            this.entries.forEach(entry => {
              reservedEntryMap.set(entry.productName, entry.id);
            });
          }

          this.searchedReservedDomains.forEach(domain => {
            if (reservedEntryMap.has(domain.domainName)) {
              domain.id = reservedEntryMap.get(domain.domainName);
            }
          });
        } else {
          this.searchedReservedDomains = [];
        }
      });
    },
    getDomainStatusLabelClass(itemDomainStatus) {
      const warningClass = 'u-yellow',
        successClass = 'u-green',
        errorClass = 'u-red',
        orangeClass = 'u-orange';

      switch (itemDomainStatus) {
        case this.domainStatusMap.reserved:
          return warningClass;
        case this.domainStatusMap.registered:
          return successClass;
        case this.domainStatusMap.pendingDelete:
          return errorClass;
        default:
          return orangeClass;
      }
    },
    displayUserDomainStatus(itemDomainStatus) {
      switch (itemDomainStatus) {
        case this.domainStatusMap.reserved:
          return this.$t('userDomains.domainStatus.reserved');
        case this.domainStatusMap.registered:
          return this.$t('userDomains.domainStatus.registered');
        case this.domainStatusMap.pendingDelete:
          return this.$t('userDomains.domainStatus.pendingDelete');
        default:
          // eslint-disable-next-line
          return itemDomainStatus;
      }
    },
    deleteSelectedRegisteredEntries() {
      const itemToDelete = this.registeredDomains.map(item => this.registeredDomains.indexOf(item));
      itemToDelete.forEach(index => {
        if (index !== -1) {
          this.registeredDomains.splice(index);
        }
      });
    },
    deleteSelectedReservedEntries() {
      const itemToDelete = this.reservedDomains.map(item => this.reservedDomains.indexOf(item));
      itemToDelete.forEach(index => {
        if (index !== -1) {
          this.reservedDomains.splice(index);
        }
      });
    },
    resetPagination() {
      this.listConfig.offset = 0;
      this.currentPage = 1;
    },
    updateTableAfterAdd(params) {
      params.forEach(domain => {
        const index = this.searchedRegisteredDomains.findIndex(item => item.id === domain.id);
        if (index !== -1) {
          this.$set(this.searchedRegisteredDomains, index, { ...this.searchedRegisteredDomains[index], addedToCart: true });
        }

        const reservedIndex = this.searchedReservedDomains.findIndex(item => item.id === domain.id);
        if (reservedIndex !== -1) {
          this.$set(this.searchedReservedDomains, reservedIndex, { ...this.searchedReservedDomains[reservedIndex], addedToCart: true });
        }
      });
    },
    updateTableWithCartItems() {
      if (this.entries) {
        this.entries.forEach(entry => {
          const registeredIndex = this.searchedRegisteredDomains.findIndex(item => item.id === entry.id);
          if (registeredIndex !== -1) {
            this.$set(this.searchedRegisteredDomains, registeredIndex, { ...this.searchedRegisteredDomains[registeredIndex], addedToCart: true });
          }
        });
      }
      if (this.entries) {
        this.entries.forEach(entry => {
          const reservedIndex = this.searchedReservedDomains.findIndex(item => item.id === entry.id);
          if (reservedIndex !== -1) {
            this.$set(this.searchedReservedDomains, reservedIndex, { ...this.searchedReservedDomains[reservedIndex], addedToCart: true });
          }
        });
      }
    },
    updateTableAfterDelete(itemId) {
      const registeredIndex = this.searchedRegisteredDomains.findIndex(item => item.id === itemId);
      if (registeredIndex !== -1) {
        this.$set(this.searchedRegisteredDomains, registeredIndex, { ...this.searchedRegisteredDomains[registeredIndex], addedToCart: false });
        const userDomain = this.userDomains.find(domain => domain.name === this.searchedRegisteredDomains[registeredIndex].name);
        if (userDomain) {
          this.searchedRegisteredDomains[registeredIndex].id = userDomain.id;
        }
      }

      const reservedIndex = this.searchedReservedDomains.findIndex(item => item.id === itemId);
      if (reservedIndex !== -1) {
        this.$set(this.searchedReservedDomains, reservedIndex, { ...this.searchedReservedDomains[reservedIndex], addedToCart: false });
        const userCapturedDomain = this.userCapturedDomains.find(domain => domain.domainName === this.searchedReservedDomains[reservedIndex].domainName);
        if (userCapturedDomain) {
          this.searchedReservedDomains[reservedIndex].id = userCapturedDomain.id;
        }
      }
    },
    goToUserDomainView(tld, domain) {
      if (!this.isAuthenticated) {
        this.$router.push({ name: 'sign-in' });
        return;
      }
      Helpers.goToView('user-domains', tld, domain, this.currentPage, this.$router);
    },
    goToUserDomainViewNewTab(e, context) {
      if (!this.isAuthenticated) {
        this.$router.push({ name: 'sign-in' });
        return;
      }
      let route = this.$router.resolve(`/user-domains/${context.tld}/${context.domain}`);
      window.open(route.href, '_blank');
    },
    onCtxOpen(locals) {
      this.contextMenuData = locals;
      this.tooltipEnabled = false;
    },
    onCtxClose() {
      this.tooltipEnabled = true;
    },
    resetCtxLocals() {
      this.contextMenuData = {};
      this.tooltipEnabled = true;
    },
    searchOnRegisteredTable() {
      this.searchedRegisteredDomains = this.searchByRegisteredDomainName(this.userDomains, this.searchRegisteredDomains).filter(domain => domain.domainStatus === 'registered' || domain.domainStatus === 'pendingDelete');
    },
    searchOnReservedTable() {
      this.searchedReservedDomains = this.searchByReservedDomainName(this.userCapturedDomains, this.searchReservedDomains)
    },
    showCopyConfirmation() {
      this.snackbar.showSnackbar = true;
    },
    showCopyConfirmationDeletedFromCart() {
      this.snackbarDeletedFromCart.showSnackbarDeletedFromCart = true;
    },
    toLower(text) {
      return text.toString().toLowerCase()
    },
    searchByRegisteredDomainName(items, term) {
      if (term) {
        return items.filter(item => this.toLower(item.name).includes(this.toLower(term)));
      } else {
        return items;
      }
    },
    searchByReservedDomainName(items, term) {
      if (term) {
        return items.filter(item => this.toLower(item.domainName).includes(this.toLower(term)));
      } else {
        return items;
      }
    },
    onSelectRegisteredDomains(userDomains) {
      this.registeredDomains = userDomains;
    },
    onSelectReservedDomains(userCapturedDomains) {
      this.reservedDomains = userCapturedDomains;
    },
  }
};
</script>

<style lang="scss">
.UserDomains .md-table-content {
  max-height: 420px !important;
}
</style>

<style lang="scss" scoped>
.UserDomains {

  .card-header-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    user-select: none;
  }

  .card-header-title {
    text-align: center;
  }

  .domain-name {
    color: $palette-primary-color-one;
    text-decoration: underline;
  }

  .md-table-row {
    cursor: pointer;
  }

  .md-table-cell {
    text-align: center;
  }

  .user-domains-preview {
    margin-bottom: 1.6rem;

    .o-default-h3 {
      margin-bottom: 1.6rem;
    }
  }

  .user-domain-status {
    color: $palette-warning;
  }

  .md-field {
    max-width: 220px;
  }

  .count-toolbar {
    display: flex;
    justify-content: space-between;
  }

  .reserved-domains-table {
    user-select: none;
  }

  .registered-domains-table {
    margin-top: 2rem;
    user-select: none;
  }

  .table-tooltip-help {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0 20px 0 0;
    cursor: pointer;
    user-select: none;
  }

  .table-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
  }

  .header-toolbar {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
</style>