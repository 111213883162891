<template>
  <div class="UserDomainsView">
    <UserDomains />
  </div>
</template>

<script>
import UserDomains from '@/components/UserDomains';

export default {
  name: 'UserDomainsView',
  components: {
    UserDomains
  }
};
</script>
